@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Kalam:wght@300;400;700&display=swap');

:root{
    --primary: #efb6b2;
    --secondary: #4e4e4e;
    --error: #ff4a4a;
  }

body{
    background-color: #07230e;
}
.custom-class{
    background-image:url(./images/IMG_9695v2.jpg)
}
.entre-background{
    background-image:url(./images/brian-chan-qJ0zGkrE1Zg-unsplash2.jpg)
}
.swakop-background{
    background-image:url(./images/grant-durr-lbMlw8p_AY8-unsplash2.jpg)
}
.whk-background{
    background-image:url(./images/ndumiso-silindza-ibu9zUnkLFw-unsplash2.jpg)
}
.about-background-1{
    background-image:url(./images/IMG_9689v3.jpg)
}
.tourPicture{
  background-image:url(./images/primaryTour.jpg)
}
.buildingPicture{
  background-image:url(./images/IMG_9698v3.jpg);
}
.lightbulbPicture{
  background-image:url(./images/lightbulbv2.jpg) ;
}
.pastorPicture{
  background-image:url(./images/pastorPicture.jpg)
}
.kidsPicture{
  background-image:url(./images/kidsPicture.jpg) ;
}
.kids2Picture{
  background-image:url(./images/kids2Picture.jpg) ;
}
.kids3Picture{
  background-image:url(./images/kids3Picture.jpg) ;
}
.logo-title{
    font-family: 'Great Vibes', cursive;
}
.glass-effect{
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(20px);
    box-shadow:0 8px 32px 0 rgba(0, 0, 0, 0.37);
    border:1px solid rgba(255, 255, 255, 0.18);
    border-radius:5px;
}

nav a.active{
    @apply  border-b-0 border-[#42b426] text-[#42b426]
}


/* upload form styles */
form{
    margin: 30px auto 10px;
    text-align: center;
  }
  label input{
    height: 0;
    width: 0;
    opacity: 0;
  }
  label{
    display: block;
    @apply h-auto w-auto px-4 py-2 bg-blue-500;
    border-radius:5px;
    margin: 10px auto;
    line-height: 30px;
    color: white;
    font-weight: bold;
    font-size: 24px;
  }
  label:hover{
    background: #077e35;
    color: white;
  }
  .output{
    height: 60px;
    font-size: 0.8rem;
  }
  .error{
    color: var(--error);
  }
  
  /* progress bar styles */
  .progress-bar{
    height: 5px;
    margin-top: 20px;
    @apply bg-accent
  }

  /* image grid styles */
.img-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    @apply mx-auto pb-20 pt-8;
  }
  .img-wrap{
    overflow: hidden;
    height: 0;
    padding: 50% 0;
    /* padding controls height, will always be perfectly square regardless of width */
    position: relative;
    opacity:1;
    box-shadow:5px 5px 5px #a7a7a76c;
  }
  .img-wrap img{
    min-width: 100%;
    min-height: 100%;
    max-width: 150%;
    position: absolute;
    top: 0;
    left: 0;
  }
  /* modal styles */
.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }
  .backdrop img{
    display: block;
    /* max-width: 60%;
    max-height: 80%;
    margin: 20% auto; */
    box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
    border: 3px solid white;
    @apply md:mt-[120px] mt-[210px] mx-auto md:max-w-[60%] md:max-h-[80%] max-w-[80%] max-h-[85%]
  }

